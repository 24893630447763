import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import FilesField from '@/shared/fields/files-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';
import StringField from '@/shared/fields/string-field';

function label(name) {
  return i18n(`entities.vorlageDienstgeber.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.vorlageDienstgeber.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  vorlage: new FilesField(
    'vorlage',
    label('vorlage'),
    Storage.values.vorlageDienstgeber,
    Permissions.getFilePermissions('vorlageDienstgeberFileFields', 'vorlage'),
    {
      size: 10485760,
      formats: ['docx'],
      required: true,
      max: 1,
    },
  ),
  name: new StringField('name', label('name'), {
    required: true,
    max: 255,
  }),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      {
        id: 'arbeitsbestaetigung',
        label: enumeratorLabel('type', 'arbeitsbestaetigung'),
      },
      {
        id: 'kuendigung',
        label: enumeratorLabel('type', 'kuendigung'),
      },
      {
        id: 'vertrag',
        label: enumeratorLabel('type', 'vertrag'),
      },
      {
        id: 'vertragsergaenzung',
        label: enumeratorLabel('type', 'vertragsergaenzung'),
      },
      {
        id: 'vertragsaenderung',
        label: enumeratorLabel('type', 'vertragsaenderung'),
      },
      {
        id: 'karenz',
        label: enumeratorLabel('type', 'karenz'),
      },
    ],
    { required: true },
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class VorlageDienstgeberModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
