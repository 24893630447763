<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/vorlage-dienstgeber' }">
        <app-i18n code="entities.vorlageDienstgeber.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.vorlageDienstgeber.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.vorlageDienstgeber.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.vorlageDienstgeber.edit.title"
          v-if="isEditing"
        ></app-i18n>
        <app-i18n
          code="entities.vorlageDienstgeber.new.title"
          v-if="!isEditing"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>

      <app-vorlage-dienstgeber-form
        :isEditing="isEditing"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
        v-if="!findLoading"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VorlageDienstgeberForm from '@/modules/vorlage-dienstgeber/components/vorlage-dienstgeber-form';
import { routerAsync } from '@/app-module';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { i18n } from '@/i18n';

export default {
  name: 'app-vorlage-dienstgeber-form-page',

  props: ['id'],

  components: {
    [VorlageDienstgeberForm.name]: VorlageDienstgeberForm,
  },

  computed: {
    ...mapGetters({
      record: 'vorlageDienstgeber/form/record',
      findLoading: 'vorlageDienstgeber/form/findLoading',
      saveLoading: 'vorlageDienstgeber/form/saveLoading',
      isSaved: 'vorlageDienstgeber/form/isSaved',
      pendingFiles: 'vorlageDienstgeber/form/pendingFiles',
    }),

    isEditing() {
      return !!this.id;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  async beforeRouteLeave(to, from, next) {
    //if status is completed we skip the dialog check and navigate to next page
    if (this.isSaved || to.name.includes('error')) {
      next();
      return;
    }

    try {
      await this.$confirm(
        i18n('common.discardFormConfirm'),
        i18n('common.confirm'),
        {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false,
        },
      );

      let response;
      if (this.pendingFiles.length > 0) {
        response = await FileUploader.removePendingFiles(this.pendingFiles);
        if (response === 200) next();
      }

      next();
    } catch (error) {
      next(false);
    }
  },

  methods: {
    ...mapActions({
      doFind: 'vorlageDienstgeber/form/doFind',
      doNew: 'vorlageDienstgeber/form/doNew',
      doUpdate: 'vorlageDienstgeber/form/doUpdate',
      doCreate: 'vorlageDienstgeber/form/doCreate',
    }),

    doCancel() {
      routerAsync().push('/vorlage-dienstgeber');
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        return this.doCreate(payload.values);
      }
    },
  },
};
</script>

<style></style>
